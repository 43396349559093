import React from "react";
import CommonDivider from "../../commonComponents/CommonDivider";

const AboutUs = () => {
  return (
    <>
      <div className="p-6">
        <CommonDivider label={"About Us"} />
        <div className="flex flex-col gap-4 mb-4">
          <div className="flex">
            <span className="text-xl font-bold mr-2">•</span>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet,
              consequuntur excepturi! Quasi debitis exercitationem facilis, sunt
              provident nihil unde enim, aut odio quod dicta dolores quisquam
              praesentium voluptas fugit? Eligendi! Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Eveniet, consequuntur excepturi!
              Quasi debitis exercitationem facilis, sunt provident nihil unde
              enim, aut odio quod dicta dolores quisquam praesentium voluptas
              fugit? Eligendi!
            </span>
          </div>
          <div className="flex">
            <span className="text-xl font-bold mr-2">•</span>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet,
              consequuntur excepturi! Quasi debitis exercitationem facilis, sunt
              provident nihil unde enim, aut odio quod dicta dolores quisquam
              praesentium voluptas fugit? Eligendi! Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Eveniet, consequuntur excepturi!
              Quasi debitis exercitationem facilis, sunt provident nihil unde
              enim, aut odio quod dicta dolores quisquam praesentium voluptas
              fugit? Eligendi!
            </span>
          </div>
          <div className="flex">
            <span className="text-xl font-bold mr-2">•</span>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet,
              consequuntur excepturi! Quasi debitis exercitationem facilis, sunt
              provident nihil unde enim, aut odio quod dicta dolores quisquam
              praesentium voluptas fugit? Eligendi! Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Eveniet, consequuntur excepturi!
              Quasi debitis exercitationem facilis, sunt provident nihil unde
              enim, aut odio quod dicta dolores quisquam praesentium voluptas
              fugit? Eligendi!
            </span>
          </div>
          <div className="flex">
            <span className="text-xl font-bold mr-2">•</span>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet,
              consequuntur excepturi! Quasi debitis exercitationem facilis, sunt
              provident nihil unde enim, aut odio quod dicta dolores quisquam
              praesentium voluptas fugit? Eligendi! Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Eveniet, consequuntur excepturi!
              Quasi debitis exercitationem facilis, sunt provident nihil unde
              enim, aut odio quod dicta dolores quisquam praesentium voluptas
              fugit? Eligendi!
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
